import { createTheme } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import createSpacing from '@material-ui/core/styles/createSpacing'
declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    beige: Palette['primary']
    beigeMedium: Palette['primary']
    backgroundBlack: Palette['primary']
    gray: Palette['primary']
    borders: Palette['primary']
  }
  interface PaletteOptions {
    beige: PaletteOptions['primary']
    beigeMedium: PaletteOptions['primary']
    backgroundBlack: PaletteOptions['primary']
    gray: PaletteOptions['primary']
    borders: PaletteOptions['primary']
  }
}

const BREAKPOINT_CONFIG = {
  // Use below if you need custom breakpoints
  // values: {
  //   xs: 0,
  //   sm: 450,
  //   md: 600,
  //   lg: 900,
  //   xl: 1200,
  // },
}

const SPACING_CONFIG = 8

/**
 * We need this in order to have the option to override
 * base component styles for specific screen sizes
 */
const breakpoints = createBreakpoints(BREAKPOINT_CONFIG)

const spacing = createSpacing(SPACING_CONFIG)

const colors = {
  beige: {
    main: '#E2E1DD',
  },
  beigeMedium: {
    main: '#CDC0A7',
  },
  backgroundBlack: {
    main: '#121212',
  },
  gray: {
    main: '#838383',
    dark: '#868686',
  },
  borders: {
    main: '#cccccc',
  },
}

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: ['ProximaNova-Regular', 'Open Sans', 'Gill Sans MT', 'Gill Sans', 'Corbel', 'Arial', 'sans-serif'].join(
      ','
    ),
    h1: {
      fontFamily: 'ProximaNova-Regular',
      fontWeight: 'bold',
      fontSize: 36,
      lineHeight: 1.17,
      letterSpacing: 2,

      [breakpoints.up('sm')]: {
        fontSize: 42,
        lineHeight: 1.19,
      },

      [breakpoints.up('lg')]: {
        fontSize: 64,
        lineHeight: 1.25,
        letterSpacing: 4,
      },
    },
    h2: {
      fontSize: 30,
      fontWeight: 'bold',
      lineHeight: 1.33,
      letterSpacing: 1,

      [breakpoints.up('sm')]: {
        fontSize: 28,
        lineHeight: 1.18,
      },

      [breakpoints.up('lg')]: {
        fontSize: 50,
        lineHeight: 1.24,
      },
    },
    h3: {
      fontSize: 26,
      fontWeight: 500,
      lineHeight: 1.31,
      letterSpacing: 1,

      [breakpoints.up('sm')]: {
        fontSize: 32,
        lineHeight: 1.19,
      },

      [breakpoints.up('lg')]: {
        fontSize: 44,
        fontWeight: 'bold',
        lineHeight: 1.18,
      },
    },
    h4: {
      fontSize: 24,
      fontWeight: 500,
      lineHeight: 1.33,
      letterSpacing: 1,

      [breakpoints.up('sm')]: {
        fontSize: 28,
        lineHeight: 1.21,
      },

      [breakpoints.up('lg')]: {
        fontSize: 34,
        lineHeight: 1.41,
      },
    },
    h5: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.4,
      letterSpacing: 1,

      [breakpoints.up('sm')]: {
        fontSize: 24,
        lineHeight: 1.25,
      },

      [breakpoints.up('lg')]: {
        fontSize: 28,
        lineHeight: 1.29,
      },
    },
    h6: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: 1.44,
      letterSpacing: 1,

      [breakpoints.up('sm')]: {
        fontSize: 20,
        lineHeight: 1.4,
      },

      [breakpoints.up('lg')]: {
        fontSize: 24,
        lineHeight: 1.42,
      },
    },
    body1: {
      fontSize: 16,
      fontWeight: 'normal',
      lineHeight: 1.25,
      letterSpacing: 0.5,

      [breakpoints.up('sm')]: {
        lineHeight: 1.5,
      },

      [breakpoints.up('lg')]: {
        fontSize: 18,
        lineHeight: 1.67,
      },
    },
    body2: {
      fontSize: 14,
      fontWeight: 'normal',
      lineHeight: 1.57,
      letterSpacing: 0.5,

      [breakpoints.up('lg')]: {
        fontSize: 16,
        lineHeight: 1.5,
      },
    },
  },
  palette: {
    beige: {
      ...colors.beige,
    },
    beigeMedium: {
      ...colors.beigeMedium,
    },
    backgroundBlack: {
      ...colors.backgroundBlack,
    },
    gray: {
      ...colors.gray,
    },
    borders: {
      ...colors.borders,
    },
    error: {
      main: '#a72002',
    },
  },
  overrides: {
    MuiLink: {
      root: {
        '&.external_link': {
          '&::before': {
            display: 'inline-block !important',
            content: 'url("/icons/icon-external-link-white.svg") !important',
            width: '32px !important',
            height: '32px !important',
            position: 'absolute',
            right: -35,
            top: '-4px !important',
          },
        },
      },
    },

    MuiContainer: {
      root: {
        paddingLeft: spacing(4.5),
        paddingRight: spacing(4.5),

        [breakpoints.up('sm')]: {
          paddingLeft: spacing(8),
          paddingRight: spacing(8),
        },
        [breakpoints.up('lg')]: {
          paddingLeft: spacing(10.25),
          paddingRight: spacing(10.25),
        },
      },
      maxWidthLg: {
        [breakpoints.up('lg')]: {
          maxWidth: 1440,
        },
      },
    },
    MuiTypography: {
      root: {
        '&.inverted': {
          color: '#ffffff',
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#ffffff',
        },
        a: {
          color: '#000',
          fontFamily: 'ProximaNova-Regular',
        },

        '#onetrust-consent-sdk': {
          position: 'relative',
          zIndex: 2220,
          maxWidth: '780px',

          '& #onetrust-banner-sdk': {
            zIndex: 2147483646,
            [breakpoints.down('xs')]: {
              backgroundColor: 'rgba(255, 255, 255, 0.3) !important',
            },

            '&.otFloatingRounded': {
              [breakpoints.down('xs')]: {
                height: '100% !important',
                maxHeight: 'none !important',
                bottom: '0 !important',
              },
            },
            '&:before': {
              content: '""',
              position: 'fixed',
              display: 'block',
              top: '0',
              left: '0',
              width: '100vw',
              height: '100vh',
              backgroundColor: 'rgba(0,0,0,.5)',
              zIndex: 0,
            },
            '& .ot-sdk-container': {
              position: 'fixed',
              display: 'block',
              backgroundColor: '#fff',
              width: 'calc(100% - 40px) !important',
              bottom: '20px',
              left: '20px',
              [breakpoints.up('md')]: {
                width: '780px !important',
              },
            },
            '& #onetrust-policy-text': {
              color: '#000000',
              fontFamily: 'ProximaNova-Regular',
            },

            '& #onetrust-button-group-parent': {
              position: 'relative',
              '& #onetrust-button-group': {
                display: 'flex',
                flexDirection: 'column',

                [breakpoints.up('sm')]: {
                  flexDirection: 'row-reverse',
                },

                '& button': {
                  minWidth: '100%',
                  textAlign: 'center',
                  fontFamily: 'ProximaNova-Regular',
                  paddingBottom: 12,
                  paddingTop: 12,
                  paddingLeft: 20,
                  paddingRight: 20,
                  alignSelf: 'flex-start',
                  borderRadius: 0,

                  [breakpoints.up('sm')]: {
                    minWidth: 158,
                    textAlign: 'left',
                  },

                  '&.cookie-setting-link': {
                    paddingLeft: 0,
                  },
                  '&#onetrust-reject-all-handler': {
                    color: '#000000',
                    borderColor: '#000000',
                    '&:hover': {
                      backgroundColor: `${colors.beige.main} !important`,
                      borderColor: `${colors.beige.main} !important`,
                    },
                  },

                  '&#onetrust-accept-btn-handler': {
                    marginLeft: 0,
                    color: '#ffffff',
                    backgroundColor: '#000000',
                    borderColor: '#000000',
                    '&:hover': {
                      color: '#000000',
                      backgroundColor: `${colors.beige.main} !important`,
                      borderColor: `${colors.beige.main} !important`,
                    },

                    [breakpoints.up('sm')]: {
                      marginLeft: '1em',
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },

  spacing: SPACING_CONFIG,

  /**
   * This will make sure we have the same breakpoints configured
   * than those used for the overrides above
   */
  breakpoints: { ...BREAKPOINT_CONFIG },
})

export default theme
