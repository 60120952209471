import React from 'react'
import { useRouter } from 'next/router'
import Head from 'next/head'
import { AppProps } from 'next/app'

import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import smoothscroll from 'smoothscroll-polyfill'

import theme from '../src/theme'

import '../styles/global.css'

declare global {
  interface Window {
    dataLayer: any
    fraud0: any
    _mtm: any
  }
}

const MerzApp: React.FC<AppProps> = (props) => {
  const { Component, pageProps } = props

  const router = useRouter()
  const win: any = typeof window !== 'undefined' ? window : { OneTrust: { Close: () => {} } }

  const onRouteChangeDone = (url: string) => {
    win?.OneTrust?.Close?.()

    if (window) {
      window?.dataLayer?.push({
        event: 'virtualPageview',
        url,
      })

      if (window._mtm) {
        // Data Layer Push mit Matomo
        window._mtm = window._mtm || [] // Initialise _mtm, if not done before
        window._mtm.push({
          event: 'customUrlChange',
          page_title: document.title,
          page_url: url || '',
        })
      }
    }
  }

  React.useEffect(() => {
    router.events.on('routeChangeComplete', onRouteChangeDone)
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeDone)
    }
  }, [onRouteChangeDone, router.events])

  React.useEffect(() => {
    if (typeof window !== undefined) {
      smoothscroll.polyfill()
    }

    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      jssStyles?.parentElement!.removeChild(jssStyles)
    }
  }, [])

  return (
    <React.Fragment>
      <Head>
        <title>Merz Pharma</title>
        <meta name="viewport" content="minimum-scale=1, maximum-scale=1, initial-scale=1, width=device-width" />
      </Head>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...pageProps} />
      </ThemeProvider>
    </React.Fragment>
  )
}

export default MerzApp
